import { useState, useContext, createContext, useEffect } from "react";
import Amplify, { Auth, auth0SignInButton, Hub } from "aws-amplify";
import jwt_decode from "jwt-decode";
export const UserContext = createContext(null);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      path: "/",
      expires: 365,
      secure: false,
    },
  },
});

Auth.configure({
  oauth: {
    domain: process.env.REACT_APP_COGNITO_HOSTED_UI,
    scope: ["phone", "email", "profile", "openid"],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
    responseType: "token",
  },
});
export function UserProvider(props) {
  //const [isAuthenticated, userHasAuthenticated] = useState(false);
  //const [isAuthenticating, setIsAuthenticating] = useState(true);
  //const [userName, setUsername] = useState(null);
  //const [tokenExpiration, setTokenExpiration] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [validLogin, setValidLogin] = useState(null);

  const haveValidToken = ()=>{
    try {
      const decoded = jwt_decode(idToken);
      if (decoded["exp"]>Math.floor(Date.now() / 1000)){
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  const getExpirationTime = () =>{
    try {
      const decoded = jwt_decode(idToken);
      return parseInt(decoded.exp) * 1000
    } catch(e){
      return 0
    }
  }

  const getUserName = ()=>{
    try {
      const decoded = jwt_decode(idToken);
      return decoded["email"];
    } catch (e) {
      return null;
    }
  }

  const isPartOfGroup = (groupName) =>{
    try {
     var decoded = jwt_decode(accessToken);
     if (decoded["cognito:groups"].includes(groupName)){
       return true;
     }else{
       return false;
     }

    } catch (e){
      return false;
    }
  }

  useEffect(() => {
    console.log("Use Effect from userContext");

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("Authenticate user:", "Hub sign in", event, data);
          // this.setState({ user: data})
          setValidLogin(haveValidToken())
          break;
        case "signOut":
          console.log("Authenticate user:", "Hub sign out");
          //userHasAuthenticated(false);
          // this.setState({ user: null })
          break;
        default:
          console.log("Authenticate user:", "Hub default");
          console.log(event);
          console.log(data);
      }
    });
    onLoad();
  }, [setValidLogin, haveValidToken]);

  useEffect(()=>{
    const timerId = setInterval(() => {
      console.log("User", "Checking Login credentials")
      if (validLogin !== haveValidToken()){
        console.log("User", "Current status not equal to state")
        setValidLogin(haveValidToken())
      } else {
        console.log("User", "Credentials still valid")
      }
    }, 60 * 5* 1000);
    return () => clearInterval(timerId);
  },[setValidLogin,haveValidToken, validLogin]);



  const onLoad = async () => {
    console.log("userContext", "OnLoad");
    try {
      console.log("userContex onload", "checking user");
      await Auth.currentSession();
      //userHasAuthenticated(true);
      console.log("usercontext onload", "User is now logged in");
    } catch (e) {
      console.log("userContext onload catch", "Not logged in, redirect");
      if (e !== "No current user") {
        //alert(e);
      }
      Auth.federatedSignIn();
    }

    //setIsAuthenticating(false);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("currentAuthenticatedUser", user);
      })
      .catch(() => {
        console.log("Not signed in");
      });

    Auth.currentSession().then((res) => {
      let fetchedAccessToken = res.getAccessToken();
      let fetchedIdToken = res.getIdToken();
      //let jwt = accessToken.getJwtToken();
      //You can print them to see the full objects
      //console.log(
      //  "Authenticate user:",
      //  `myAccessToken: ${JSON.stringify(accessToken)}`
      //);
      //console.log("Authenticate user:", `myJwt: ${jwt}`);
      //console.log(
      //  "Authenticate user:",
      // `myIdToken: ${JSON.stringify(idToken)}`
      //);
      //let email = idToken.payload.email;
      //console.log(email);
      //setUsername(email);
      //setTokenExpiration(parseInt(fetchedIdToken.payload.exp) * 1000);
      setAccessToken(fetchedAccessToken.getJwtToken());
      setIdToken(fetchedIdToken.getJwtToken());
    });
  };

  const handleLogout = async () => {
    await Auth.signOut();
    //userHasAuthenticated(false);
  };
  const contextValue = {
    //isAuthenticated,
    //userHasAuthenticated,
    //isAuthenticating,
    //setIsAuthenticating,
    handleLogout,
    //userName,
    getExpirationTime,
    accessToken,
    isPartOfGroup,
    getUserName,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {haveValidToken() ? props.children :<div>Token expired</div>
      }
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
