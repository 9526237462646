import React from 'react';
import Form from "react-bootstrap/Form";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useSettingsContext} from "../../Context/settingsContext"
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

function getObjProp(obj, propString) {
  if (!propString)
    return obj;

  var prop, props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

const updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('.');
    !rest.length
    ? obj[head] = value
    : this.updateObjProp(obj[head], value, rest.join('.'));
}

function leaf(obj, path, value) {
  const pList = path.split('.');
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
}

const SettingsParameter = ({name,path}) =>{
    const { currentSettings, setSettings} = useSettingsContext();

    const handleChange = (event)=>{
        let tmp = {...currentSettings}
        leaf(tmp, path+".value",parseFloat(event.target.value));
        setSettings(tmp)
    }
    const toggleChange = (event)=>{
        let tmp = {...currentSettings}
        leaf(tmp, path+".value",!getObjProp(currentSettings,path+".value"));
        setSettings(tmp)
    }
    const renderInput = ()=>{
        switch (getObjProp(currentSettings,path +".type")){
            case 'int':
            case 'float':
                return (
                <>
                <Col sm="2">
                    <Form.Range key={getObjProp(currentSettings,path+".value")} defaultValue={getObjProp(currentSettings,path +".value")} step={getObjProp(currentSettings,path +".step")}
                        min={getObjProp(currentSettings,path +".min")} max={getObjProp(currentSettings,path +".max")} onChange={e => handleChange(e)}/>
                </Col>
                <Col sm="2">
                    <Form.Control key={getObjProp(currentSettings,path+".value")} value={getObjProp(currentSettings,path +".value")} disabled/>
                </Col>
                <Col sm="3">
                    {getObjProp(currentSettings,path+".text")}
                </Col>
                </>
                )
            case 'bool':
                return(
                <>
                    <Col sm="1">
                  <ToggleButton className="mb-2" id={path} type="checkbox" size="sm" key={path}
                    variant="outline-primary" checked={getObjProp(currentSettings,path+".value")} value="1"
                    onChange={(e) => toggleChange(e)} > {getObjProp(currentSettings,path+".value")?"On":"Off"}</ToggleButton>
                    </Col>
                </>
                )
            default:
                return <Form.Control  key={getObjProp(currentSettings,path)+getObjProp(currentSettings,path+".value")} defaultValue={getObjProp(currentSettings,path +".value")} onChange={handleChange} />
        }
    }
    return(
        <Form.Group  as={Row} controlId={name}>
            <Form.Label column sm="2"> {path} </Form.Label>
            {renderInput()}
        </Form.Group>
    )
}
export default SettingsParameter;