import { useState, useContext, createContext, useEffect } from "react";

export const SettingsContext = createContext(null);




export function SettingsProvider(props) {
  const defaultSettings = {
      general:{
        wake_lock: {value: true, type:"bool"}
      },
      graph:{
        graph_scale_x: {value: 70, type:"int",min:1,max:100,step:1,text:"Scaling factor graph x"},
        graph_scale_y: {value: 70, type:"int",min:1,max:100,step:1,text:"Scaling factor graph y"}
      },
      graph_instr:{
          stocks: {value: true, type:"bool",instr:0},
          pref: {value: false, type: "bool",instr:1},
          lists: {value: false, type: "bool",instr:2},
          three: {value: false, type:"bool", instr:3},
          markets: {value: false, type:"bool", instr:4},
          se_markets:{value: false, type:"bool",instr:5},
          spac:{value: false, type:"bool", instr:8},
          thirteen:{value: false, type:"bool", instr:13},
      },
      tickers:{
        simulate: {value: true, type:"bool"},
        sample_time: {value: 1000, type:"int", min:10,max:2000, step:10, text:"Time [ms] between sim samples"}
      },
      sensors:{
        update_state:{value:0.5, type:"float",min:0.1,max:10,step:0.1,text:"Time [s] to update state with sensor data"}
      },
      filtering:
      {
        avg_samples:{value:20,type:"int",min:1,max:200,step:1, text:"Nr of samples to average"},
        samples_hist:{value:500,type:"int",min:10,max:4000,step:10,text:"Nr of Samples to keep in history"}
      },
      plot:{
        samplesplot:{value:20,type:"int",min:1,max:200,step:1,text:"Nr of sample to plot"},
        plot_ratio:{value:0.90,type:"float",min:0.1,max:1,step:0.1,text:"Size of plot"},
      },
  };

  const [currentSettings, setSettings] = useState(() => {
    try {
      return {...defaultSettings,...JSON.parse(localStorage.getItem("settings"))} ?? defaultSettings;/*global localStorage*/
    } catch {
      return defaultSettings;
     }
  });



  const setToDefaultSettings = ()=>{
    alert("Resetting")
    setSettings(defaultSettings);
  }

  const contextValue = {
    currentSettings,
    setSettings,
    setToDefaultSettings
  };

  /* Save to local storage if current settings is changed*/
  useEffect(()=>{
    localStorage.setItem("settings", JSON.stringify(currentSettings));
  }, [currentSettings])

  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
}

export function useSettingsContext() {
  return useContext(SettingsContext);
}