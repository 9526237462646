import React, {useEffect, PureComponent,useState, useRef, useLayoutEffect} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useUserContext } from "../Context/userContext";
import { useApiContext } from "../Context/apiContext";
import { useResizeContext} from "../Context/resizeContext";
import {useSettingsContext} from "../Context/settingsContext"

export const Graphs = () =>{
    const { accessToken } = useUserContext();
    const { availableTickers } = useApiContext();
    const { currentSettings} = useSettingsContext();
    const {width,height} = useResizeContext();
    const [plotdata, setPlotData] = useState();
    const [fftdata, setFftData] = useState();

    const [graphWidth, setGraphWidth] = useState(400);
    const [graphHeight, setGraphHeight] = useState(400);
    const [ticker, setTicker] = useState("ABB");
    const [days, setDays] = useState(90);


    const divRef = useRef();



    useEffect(()=>{
        console.log("Fetching data")
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/data/${ticker}?days=${days}`, {
            method: "GET",
            headers: {"Authorization": `Bearer ${accessToken}`}
            }).then(res => res.json()).then(json => {
                setPlotData(json.data.map((data,idx) => ({name:data[0],[ticker]:data[1]})));
                console.log(json)
            });
    },[ticker, days]);

    useLayoutEffect(() => {
        setGraphWidth(width*currentSettings.graph.graph_scale_x.value/100)
        setGraphHeight(height*currentSettings.graph.graph_scale_y.value/100)
        console.log(graphHeight)
        console.log(graphWidth)
    }, [width,height]);

    const Dropdown = ({ label, value, options, onChange }) => {
        return (
            <label>
                {label}
                <select value={value} onChange={onChange}>
                    {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </label>
        );
    };

    const DropdownTicker = ({ label, value, options, onChange }) => {
        return (
            <label>
                {label}
                <select value={value} onChange={onChange}>
                    {options.map((option) => (
                    <option value={option}>{option}</option>
                    ))}
                </select>
            </label>
        );
    };

    const options = [
        { label: 'ABB', value: 'ABB' },
        { label: 'AZA', value: 'AZA' },
        { label: 'AZN', value: 'AZN' },
        { label: 'AFRY', value: 'AFRY'},
    ];


    const handleChange = (event) => {
        setTicker(event.target.value);
    };
    const handleTimeChange = (event) => {
        setDays(event.target.value);
    };
    return (
        <>
        <div>
            <Dropdown
                label="Ticker"
                options={availableTickers.map((row=> ({label: row[1], value: row[0]})))}
                value={ticker}
                onChange={handleChange}
            />
             <Dropdown
                label="Timespan"
                options={[{label: '360',value:360},{label: '180',value:180}, {label: '120',value:120},{label: '90',value:90},{label: '30',value:30}]}
                value={days}
                onChange={handleTimeChange}
            />
        </div>
        <div ref={divRef}>
        {plotdata ?
        <LineChart width={graphWidth} height={graphHeight} data={plotdata} margin={{ top: 30, right: 30, left: 20, bottom: 5, }}>
           <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="linear" dataKey={ticker} stroke="#8884d8" />
        </LineChart>
        :''}
        </div>
        </>
    );
}

export default Graphs;
