import { React, useState, useContext, createContext, useEffect } from "react";
import { useUserContext } from "../Context/userContext";

export const ApiContext = createContext(null);

export function ApiProvider(props) {
    const { accessToken } = useUserContext();
    const [availableTickers, setAvailableTickers] = useState([]);

    useEffect(()=>{
        console.log("Fetching data")
        fetch(process.env.REACT_APP_API_ENDPOINT + '/v1/tickers', { /*global fetch */
            method: "GET",
            headers: {"Authorization": `Bearer ${accessToken}`}
            }).then(res => res.json()).then(json => setAvailableTickers(json.tickers));

    },[]);

    useEffect(()=>{
        console.log(availableTickers)
    },[availableTickers])

    const contextValue = {
        availableTickers,
    };

    return (
        <ApiContext.Provider value={contextValue}>
            {props.children}
        </ApiContext.Provider>
    );
}

export function useApiContext() {
  return useContext(ApiContext);
}
