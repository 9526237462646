import './App.css';


import Webpages from './webpages';


function App() {

  return (
    <div className="App">
        <Webpages />
    </div>
  );
}

export default App;
