import { useState, useContext, createContext, useEffect } from "react";

export const resizeContext = createContext(null);

export function ResizeProvider(props) {
  const [landscape, setLandscape] = useState(window.innerWidth>window.innerHeight? true:false)
  const [width,setWidth] = useState(window.innerWidth)
  const [height,setHeight] = useState(window.innerHeight)
  const [onMobile, setOnMobile] = useState(
    window.innerWidth < 750 ? true : false
  );
  const [showSide, setShowSide] = useState(false);
  useEffect(() => {
    function handleResize() {
      console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
      setOnMobile(window.innerWidth < 750 ? true : false);
      setLandscape(window.innerWidth>window.innerHeight? true:false)
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const contextValue = {
    onMobile,
    landscape,
    showSide,
    setShowSide,
    width,
    height
  };

  return (
    <resizeContext.Provider value={contextValue}>
      {props.children}
    </resizeContext.Provider>
  );
}

export function useResizeContext() {
  return useContext(resizeContext);
}
