import React from 'react';
import Form from "react-bootstrap/Form";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SettingsParameter from './SettingsParameter.js';

const SettingsParagraph = ({path,settings}) =>{
    return(
    <>
        <Row>
          <Col xs={2}><h2>{path}</h2></Col>
        </Row>
      {Object.keys(settings[path]).map((key2, index2) =>{
            return( <SettingsParameter name={key2} path={path + "."+key2} /> )
      })}
    </>
      )
}

export default SettingsParagraph;