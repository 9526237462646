//file: src/webpages/home.js
import React from 'react';
const Home = () => {
    return (
        <div>
            <h1>Stock analyser</h1>
            <p>This is home page to make it easier to find good stocks</p>
        </div>
    );
};
export default Home;