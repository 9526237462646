import React, { useEffect, useState } from 'react';
import {useSettingsContext} from "../Context/settingsContext"
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import SettingsParagraph from '../Components/Settings/SettingsParagraph.js'
import Container from 'react-bootstrap/Container';
const Settings = ()=>{
  const { currentSettings, setToDefaultSettings} = useSettingsContext();
  return(
    <>
      <Button className="d-flex justify-content-start" variant="warning" onClick={() => setToDefaultSettings()} >Reset to default</Button>
      <Container fluid> {Object.keys(currentSettings).map((key, index) =>{
          return( <SettingsParagraph path={key} settings={currentSettings} /> )
        })}
      </Container>
    </>
    )
}

export default Settings