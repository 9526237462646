//file: src/webpages/index.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Layout from '../Components/Layout';

import Home from './home';
import Tickers from './tickers';
import Favorites from './favorites';
import Graphs from './graphs';
import Settings from './settings';

const Webpages = (props) => {
    return(
        <Router>
        <Layout>
            <Routes>

            <Route path="/" element={<Home />} />
            <Route path = "/tickers" element= {<Tickers />} />
            <Route path = "/favorites" element = {<Favorites />} />
            <Route path = "/graphs" element = {<Graphs data={props.data}/>} />
            <Route path = "/settings" element = {<Settings data={props.data}/>} />
        </Routes>
        </Layout>
        </Router>
    );
};
export default Webpages;