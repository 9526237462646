//file: src/webpages/mybooks.js
import React, { useEffect, useState } from 'react';

import { useUserContext } from "../Context/userContext";
import { useApiContext} from "../Context/apiContext";

const Tickers = () => {
    const { accessToken } = useUserContext();
    const { availableTickers } = useApiContext();

    return (
        <div>
            <h1>Tickers</h1>
            <p>These are the tickers currently in the timestream</p>


            <table>
            <thead>
                <tr>
                <th>Index</th>
                <th>Ticker</th>
                <th>Name</th>
                <th>Country</th>
                <th>Sector</th>
                <th>Market</th>
                <th>Branch</th>
                <th>Instrument</th>
                </tr>
            </thead>
            <tbody>
                {availableTickers ? (
                availableTickers.map((message, idx) => (
                    <tr key={idx}>
                    <td>{idx}</td>
                    <td>{message[0]}</td>
                    <td>{message[1]}</td>
                    <td>{message[2]}</td>
                    <td>{message[3]}</td>
                    <td>{message[4]}</td>
                    <td>{message[5]}</td>
                    <td>{message[6]}</td>
                    </tr>
                ))
                ) : (
                <tr></tr>
                )}
            </tbody>
            </table>

        </div>
    );
};
export default Tickers;