import React from 'react';
//import logo from '../images/xvlogo.png';
import styled from 'styled-components';
import { useUserContext } from "../Context/userContext";

const HeaderBar = styled.header`
    width: 100%;
    padding: 0.5em 1em;
    display: flex;
    height: 64px;
    position: fixed;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
`;


// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 1.5em;
  text-align: left;
  color: palevioletred;
`;

const User = styled.div`
   font-size: 1em;
   margin-left:auto;
   margin-right:1em;
   position: right;
   text-align: right;
`;


const Logout = styled.div`
    display: flex;
    max-width: 100em;
    height: 64px;
    position: right;
    align-items: center;
    background-color: #ffa;
    z-index: 1;
    margin-left: auto;
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

const Header = () => {
    const { getUserName, handleLogout, getExpirationTime } = useUserContext();
    return (
        <>
        <HeaderBar>
        <Title>Sumero</Title>


        <Logout>
            <User>{getUserName()}</User>
            <Button onClick={handleLogout}>Log out</Button>
        </Logout>
        </HeaderBar>

        </>
        );
    };
export default Header;